import React from "react";
import { Card } from "react-bootstrap";
import TableProvider from "components/tables/btable/provider";
import { AgencyUsageReportTable } from "components/tables";
import * as agencyAPI from "api2/agencies";

function AdminUsageReportPage() {
  const initialFilters = {
    month_holder: new Date(),
    ordering: "agency_name",
  };

  return (
    <TableProvider
      tableId="usage-report-table"
      dataSource={(filters, config) => agencyAPI.usageReports.list(filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <AgencyUsageReportTable />
        </Card.Body>
      </Card>
    </TableProvider>
  );
}

export default AdminUsageReportPage;
