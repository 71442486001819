import React from "react";
import { MonthDateFilter, MultiAgencyFilter } from "components/filters";

import useTable from "components/tables/btable/useTable";
import { useTranslation } from "react-i18next";

function AgencyUsageReportFilters() {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation();
  return (
    <section className="table-filters-left">
      <MultiAgencyFilter
        name="agencies"
        onFilter={({ agencies }) => {
          setFilters({ agencies });
        }}
        label={t("common:agency")}
        defaultValue={filters.agencies}
      />
      <MonthDateFilter
        label={t("common:dates.month")}
        isClearable={false}
        defaultValue={filters.month_holder}
        onChange={(date) => {
          setFilters({ month_holder: date || new Date() });
        }}
      />
    </section>
  );
}

export default AgencyUsageReportFilters;
