import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BTable from "components/tables/btable";
import { formatMonth } from "utils/date";
import Filters from "./AgencyUsageReportFilters";
import Actions from "./AgencyUsageReportActions";

function AgencyUsageReportTable() {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "agency_name",
        label: t("common:agencyName"),
      },
      {
        field: "month_holder",
        label: t("common:dates.month"),
        render: (row) => {
          return formatMonth(row.month_holder);
        },
      },
      {
        field: "companies_yearly_count",
        label: t("common:companiesYearly"),
        sortField: "companies_yearly",
      },
      {
        field: "companies_accounting_count",
        label: t("common:companiesAccounting"),
        sortField: "companies_accounting",
      },
    ],
    [t]
  );

  return (
    <BTable
      headers={headers}
      tableClassName="sticky-header"
      tableResponsive={false}
      FiltersComponent={<Filters />}
      renderActions={(row) => <Actions row={row} />}
    />
  );
}

export default AgencyUsageReportTable;
