import { NavLink, useResolvedPath } from "react-router-dom";
import React from "react";
import LeftSidebar from "components/ui/sidebar";
import { useTranslation } from "react-i18next";
import { CodeRequiredInList, codesFor } from "components/perms";
import LayoutSwitcherMenuItem from "../LayoutSwitcherMenuItem";

function Menu({ company, userPermCodes }) {
  const url = useResolvedPath("").pathname;
  const { t } = useTranslation("navigation");
  const ttOnly = company.status === "basic";

  return (
    <LeftSidebar>
      <LayoutSwitcherMenuItem />
      <li className="menu-title">{t("companyConfig")}</li>
      <li>
        <NavLink to={url} end replace>
          <i className="fe-box" />
          <span> {t("general")}</span>
        </NavLink>
        <CodeRequiredInList code={codesFor.employees.enabled} codeList={userPermCodes}>
          <NavLink to={`${url}/employees`} replace>
            <i className="fe-users" />
            <span> {t("employees")}</span>
          </NavLink>
        </CodeRequiredInList>
        {!ttOnly && (
          <>
            <NavLink to={`${url}/invoicing`} replace>
              <i className="fe-file-text" />
              <span> {t("invoicing")}</span>
            </NavLink>

            <NavLink to={`${url}/projects`} replace>
              <i className="fe-folder" />
              <span> {t("projects")}</span>
            </NavLink>
            <NavLink to={`${url}/cost-centers`} replace>
              <i className="fe-layers" />
              <span> {t("costCenters")}</span>
            </NavLink>
            <CodeRequiredInList code={codesFor.consultManage.enabled} codeList={userPermCodes}>
              <NavLink to={`${url}/budget/profit-and-loss`} replace>
                <i className="fe-activity" />
                <span> {t("budget")}</span>
              </NavLink>
            </CodeRequiredInList>
          </>
        )}
        <CodeRequiredInList code={codesFor.financialYears.enabled} codeList={userPermCodes}>
          <NavLink to={`${url}/financial-years`} replace>
            <i className="fe-clock" />
            <span> {t("financialYears")}</span>
          </NavLink>
        </CodeRequiredInList>
        {!ttOnly && (
          <CodeRequiredInList code={codesFor.companySettings.accountPlan} codeList={userPermCodes}>
            <NavLink to={`${url}/account-plan`} replace>
              <i className="fe-share-2" />
              <span> {t("accountPlan")}</span>
            </NavLink>
          </CodeRequiredInList>
        )}
        <NavLink to={`${url}/logo`} replace>
          <i className="fe-image" />
          <span> {t("companyLogo")}</span>
        </NavLink>
        {!ttOnly && (
          <>
            <CodeRequiredInList code={codesFor.companySettings.bankConnection} codeList={userPermCodes}>
              <NavLink to={`${url}/bank-connections`} replace>
                <i className="fe-database" />
                <span> {t("bank")}</span>
              </NavLink>
            </CodeRequiredInList>
            <CodeRequiredInList code={codesFor.companySettings.bankMatchingRules} codeList={userPermCodes}>
              <NavLink to={`${url}/bank-matching-rules`} replace>
                <i className="fe-eye" />
                <span> {t("bankMatchingRules")}</span>
              </NavLink>
            </CodeRequiredInList>
            <CodeRequiredInList code={codesFor.companySettings.sieFileImport} codeList={userPermCodes}>
              <NavLink to={`${url}/SIE`} replace>
                <i className="fe-file-plus" />
                <span> {t("sieFile")}</span>
              </NavLink>
            </CodeRequiredInList>
            <CodeRequiredInList code={codesFor.consultManage.enabled} codeList={userPermCodes}>
              <NavLink to={`${url}/annual-report`} replace>
                <i className="fe-file-text" />
                <span> {t("annualReport")}</span>
              </NavLink>
            </CodeRequiredInList>
            <NavLink to={`${url}/time-reporting`} replace>
              <i className="fe-clock" />
              <span> {t("timeReport")}</span>
            </NavLink>
          </>
        )}
        <CodeRequiredInList code={codesFor.auditors.enabled} codeList={userPermCodes}>
          <NavLink to={`${url}/auditors`} replace>
            <i className="fe-share-2" />
            <span> {t("auditors")}</span>
          </NavLink>
        </CodeRequiredInList>
        {company.tt_active_date && (
          <CodeRequiredInList code={codesFor.companySettings.tt} codeList={userPermCodes}>
            <NavLink to={`${url}/tt`} replace>
              <i className="fe-calendar" />
              <span> TT</span>
            </NavLink>
          </CodeRequiredInList>
        )}
        <CodeRequiredInList code={codesFor.companySettings.history} codeList={userPermCodes}>
          <NavLink to={`${url}/history`} replace>
            <i className="fe-clock" />
            <span> {t("processingHistory")}</span>
          </NavLink>
        </CodeRequiredInList>
        <CodeRequiredInList code={codesFor.companySettings.dataExport} codeList={userPermCodes}>
          <NavLink to={`${url}/export`} replace>
            <i className="fe-download" />
            <span> {t("export")}</span>
          </NavLink>
        </CodeRequiredInList>
        <CodeRequiredInList code={codesFor.consultManage.enabled} codeList={userPermCodes}>
          <NavLink to={`${url}/consult`} replace>
            <i className="fe-lock" />
            <span> {t("admin")}</span>
          </NavLink>
        </CodeRequiredInList>
      </li>
    </LeftSidebar>
  );
}

export default Menu;
