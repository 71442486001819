import { saveAs } from "file-saver";

export function getFileNameDispositionContent(response) {
  const disposition = response.headers["content-disposition"];
  if (disposition && (disposition.indexOf("inline" !== -1) || disposition.indexOf("attachment") !== -1)) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, "").replace(/^.*[\\/]/, "");
    }
  }
  return "";
}

export function saveBlobToComputer(response, defaultFileName) {
  const fileName = getFileNameDispositionContent(response) || defaultFileName;
  saveAs(response.data, fileName);
}
