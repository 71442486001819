import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";

import { byMonths, byWeekDays } from "api/options";
import { RepeatedTaskEditWidget } from "components/forms/company-settings-forms/RepeatedTaskForm";
import Filters from "./RepeatedTaskFilters";
import "./RepeatedTaskExtendedTable.scss";

function formatByWeekDay(byWeekDay) {
  if (byWeekDay) {
    return byWeekDay
      .split(",")
      .map((value) => byWeekDays.getLabel(value))
      .join(", ");
  }
  return "";
}

function formatByMonth(byMonth) {
  if (byMonth) {
    return byMonth
      .split(",")
      .map((value) => byMonths.getLabel(value))
      .join(", ");
  }
  return "";
}

function RepeatedTaskExtendedTable({ companyId }) {
  const { t } = useTranslation("company");
  const headers = useMemo(
    () => [
      {
        field: "title",
        label: t("common:name"),
      },
      {
        field: "byweekday",
        label: t("byWeekDay"),
        canSort: false,
        render: (row) => formatByWeekDay(row.byweekday),
      },
      {
        field: "bymonthday",
        label: t("byDate"),
        canSort: false,
      },
      {
        field: "bymonth",
        label: t("byMonth"),
        canSort: false,
        render: (row) => formatByMonth(row.bymonth),
      },
      {
        field: "bysetpos",
        label: t("bySetPos"),
        canSort: false,
      },
      {
        field: "assignee_name",
        label: t("common:consult"),
        canSort: false,
      },
      {
        field: "active",
        label: t("common:statuses.active"),
        render: (row) => (row.active ? t("common:actions.yes") : t("common:actions.no")),
      },
    ],
    [t]
  );

  return (
    <Card className="repeated-task-table">
      <Card.Body>
        <BExpandedTable
          headers={headers}
          FiltersComponent={<Filters companyId={companyId} />}
          renderExpandedRow={(row) => <RepeatedTaskEditWidget taskId={row.id} companyId={companyId} />}
        />
      </Card.Body>
    </Card>
  );
}

export default RepeatedTaskExtendedTable;
