import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import cx from "classnames";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { useAuthDispatch } from "../../../hooks/useAuth";
import { FormGroup } from "../../../components/formik";
import { SubmitButton } from "../../../components/ui/buttons";
import AdminNotificationLoginAlert from "./AdminNotificationLoginAlert";

function EmailPasswordLoginForm() {
  const { t } = useTranslation(["others", "common"]);
  const { standardLogin, login2fResendCode } = useAuthDispatch();
  const codeResentRef = useRef(false); // allow only 1 reset per session
  const [showCodeFields, setShowCodeFields] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const formikProps = {
    initialValues: {
      email: "",
      password: "",
      code: "",
      save_device: false,
      dummy_show_code_fields: true,
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
      password: yup.string().required(),
      show_code_fields: yup.boolean(),
      code: yup.string().when("dummy_show_code_fields", {
        is: showCodeFields && !resendCode,
        then: () => yup.string().required(),
        otherwise: () => yup.string().nullable(true),
      }),
    }),

    onSubmit: (values) => {
      values.resend_code = resendCode;
      if (resendCode) {
        values.code = "";
      }
      return standardLogin(values)
        .then((response) => {
          setResendCode(false);
          if (response.status === 200) {
            toast.success(t("msg:signedIn"), { autoClose: 2000 });
          } else {
            setShowCodeFields(true);
            toast.info(t("msg:signRequireCode"), { autoClose: 5000 });
          }
        })
        .catch((response) => {
          setResendCode(false);
          if (response.data?.__all__) {
            toast.error(response.data.__all__); // eg. wrong 2Factor code
          } else {
            toast.error(t("common:errors.sthWentWrong"));
          }
        });
    },
  };

  const resendCodeEmail = (email, password) => {
    if (codeResentRef.current === true) {
      toast.info(t("msg:signRequireCode"), { autoClose: 5000 });
      return;
    }
    login2fResendCode(email, password)
      .then((response) => {
        codeResentRef.current = true;
        toast.info(t("msg:signRequireCode"), { autoClose: 5000 });
      })
      .catch((response) => {
        if (response.data?.__all__) {
          toast.error(response.data.__all__);
        } else {
          toast.error(t("common:errors.sthWentWrong"));
        }
      });
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, values }) => {
        return (
          <Form noValidate>
            <div className="box box-pwd">
              <h3 className="mb-4">{t("others:bflowWelcome")}</h3>
              <AdminNotificationLoginAlert />

              <div className={cx([showCodeFields ? "d-none" : "", "inner-box"])}>
                <FormGroup.Input
                  name="email"
                  type="email"
                  placeholder={t("common:contact.emailLong")}
                  autoFocus
                  disabled={showCodeFields}
                />
                <FormGroup.Input
                  name="password"
                  type="password"
                  placeholder={t("auth.password")}
                  disabled={showCodeFields}
                />
                <Link className="text-right side-link mt-0" to="/auth/password-forgot">
                  {t("others:auth.forgotPassword")}
                </Link>
              </div>
              {showCodeFields && (
                <>
                  <FormGroup.Input name="code" type="text" placeholder={t("others:auth.code")} />
                  <FormGroup.Checkbox
                    wrapperClass="align-self-start mt-1"
                    name="save_device"
                    type="checkbox"
                    label={t("others:auth.saveDevice")}
                  />
                </>
              )}
              <div className="w-100 d-flex justify-content-between">
                {showCodeFields ? (
                  <Button
                    size="lg"
                    icon=""
                    className="btn-resend"
                    variant="toggle-white"
                    title={t("others:auth.resendCode")}
                    disabled={isSubmitting && resendCode}
                    onClick={() => resendCodeEmail(values.email, values.password)}
                  >
                    {t("others:auth.resendCode")}
                  </Button>
                ) : null}
                <SubmitButton
                  size="lg"
                  variant="white"
                  className="width-lg"
                  title="others:auth.signIn"
                  icon=""
                  disabled={isSubmitting}
                />
              </div>
              <Link to="/auth/login" className="side-link">
                {t("others:login.bankId")}
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default EmailPasswordLoginForm;
