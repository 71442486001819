import React from "react";
import { TooltipActionButton } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import { FileUrlModal } from "components/modals";
import useModal from "hooks/useModal";

function AgencyUsageReportActions({ row }) {
  const { t } = useTranslation("navigation");
  const fileModal = useModal();

  const downloadAgencyData = () => {
    fileModal.open(`/agencies/usage-reports/${row.id}/pdf/`);
  };

  return (
    <>
      <TooltipActionButton text={t("common:actions.download")} onClick={downloadAgencyData} icon="fas fa-download" />
      {fileModal.show && <FileUrlModal fileUrl={fileModal.data} handleClose={fileModal.close} autoDownloadAndPreview />}
    </>
  );
}

export default AgencyUsageReportActions;
