import { isAfter } from "date-fns";
import client from "./client";
import { isBefore, parseDate } from "../utils/date";

export function companyEmployeeAgencyAbsences(companyId, filters, config) {
  return client
    .get("/absence-reports/agency/", {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) => {
      response.data.forEach((report) => {
        report.title = `Report ${report.user_name}`;
      });
      return response;
    });
}

export function companyEmployeeAbsences(companyId, filters, config) {
  return client
    .get("/absence-reports/", {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) => {
      // group logs by periods
      response.data.forEach((report) => {
        const tasks = {};
        report.title = `Report ${report.user_name}`;
        report.logs.forEach((log) => {
          const started = parseDate(log.started);
          const stopped = parseDate(log.stopped);
          if (!tasks[log.task]) {
            tasks[log.task] = {
              started,
              stopped,
              task_title: log.task_title,
              task_comment: log.task_comment,
              id: log.task,
              hours: log.hours,
            };
          } else {
            if (isBefore(started, tasks[log.task].started)) {
              tasks[log.task].started = started;
            }
            if (isAfter(stopped, tasks[log.task].stopped)) {
              tasks[log.task].stopped = stopped;
            }
            tasks[log.task].hours += log.hours;
          }
        });
        report.tasks = Object.values(tasks);
      });
      return response;
    });
}

export function approve(companyId, reportId) {
  return client.patch(`/absence-reports/${reportId}/approve/`, null, {
    headers: {
      "x-company": companyId,
    },
  });
}

export function dispute(companyId, reportId, values) {
  return client.patch(`/absence-reports/${reportId}/dispute/`, values, {
    headers: {
      "x-company": companyId,
    },
  });
}

export const comment = {
  list: (companyId, reportId) => {
    return client.get(`/absence-reports/${reportId}/comments/`, {
      params: {
        page_size: 100,
      },
      headers: {
        "x-company": companyId,
      },
    });
  },
  remove: (companyId, reportId, commentId) => {
    return client.delete(`/absence-reports/${reportId}/comments/${commentId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
    });
  },
  create: (companyId, reportId, text) => {
    return client.post(
      `/absence-reports/${reportId}/comments/create/`,
      { text },
      {
        headers: {
          "x-company": companyId,
        },
      }
    );
  },
};

export function history(companyId, reportId, config) {
  return client.get(`/absence-reports/${reportId}/history/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}
