import React, { useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Button, ButtonGroup } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import useTable from "components/tables/btable/useTable";
import { BankRuleEditWidget } from "components/forms/company-settings-forms/BankRuleForm";
import { SubmitButton, TooltipActionButton } from "components/ui/buttons";
import * as companyApi from "api2/companies";
import { toast } from "react-toastify";
import useModal from "../../../hooks/useModal";
import { FileUrlModal } from "../../modals";

function BankRulesExtendedTable({ companyId, companyAccounts }) {
  const { t } = useTranslation(["company", "options"]);
  const {
    data,
    dataActions: { moveRow, reload },
  } = useTable();
  const [editMode, setEditMode] = useState(false);
  const [priorityList, setPriorityList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useMemo(() => {
    if (editMode) {
      const initialPriorityList = data.map((item, index) => ({
        ...item,
        priority: index + 1,
      }));
      setPriorityList(initialPriorityList);
    }
  }, [data, editMode]);

  const moveUp = useCallback(
    (id) => {
      moveRow(id, "up");

      setPriorityList((prevList) => {
        const index = prevList.findIndex((item) => item.id === id);
        if (index > 0) {
          const updatedList = [...prevList];
          updatedList[index].priority += 1;
          updatedList[index - 1].priority -= 1;
          return updatedList;
        }
        return prevList;
      });
    },
    [moveRow]
  );

  const moveDown = useCallback(
    (id) => {
      moveRow(id, "down");

      setPriorityList((prevList) => {
        const index = prevList.findIndex((item) => item.id === id);
        if (index < prevList.length - 1) {
          const updatedList = [...prevList];
          updatedList[index].priority += 1;
          updatedList[index + 1].priority -= 1;
          return updatedList;
        }
        return prevList;
      });
    },
    [moveRow]
  );

  const headers = useMemo(
    () => [
      {
        field: "rule_id",
        label: "#",
        canSort: false,
        width: 50,
      },
      {
        field: "condition",
        label: t("bankRule.condition"),
        render: ({ condition }) => t(`options:${condition}`),
      },
      {
        field: "search_text",
        label: t("bankRule.text"),
      },
      {
        field: "priority",
        label: t("bankRule.priority"),
        render: (row) => {
          if (editMode) {
            const currentItem = priorityList.find((item) => item.id === row.id);
            if (!currentItem) return null;

            return (
              <div className="td-actions">
                <TooltipActionButton
                  text={t("common:actions.priorityUp")}
                  icon="fas fa-arrow-up"
                  disabled={currentItem.priority === 1}
                  onClick={() => moveUp(row.id)}
                />
                <TooltipActionButton
                  text={t("common:actions.priorityDown")}
                  icon="fas fa-arrow-down"
                  onClick={() => moveDown(row.id)}
                  disabled={currentItem.priority === priorityList.length}
                />
              </div>
            );
          }
          return row.priority;
        },
      },
    ],
    [t, editMode, priorityList, moveUp, moveDown]
  );

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    reload();
  };

  const handleSaveClick = () => {
    setIsSubmitting(true);
    const changedItems = priorityList
      .filter((item, index) => item.priority !== data[index].priority)
      .map((item) => ({ id: item.id, priority: item.priority }));

    companyApi.bankMatchingRules
      .priorityUpdate(companyId, changedItems)
      .then(() => {
        toast.success(t("msg:updated"));
      })
      .catch((error) => {
        if (error.data && error.data.__all__) {
          toast.error(error.data.__all__);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
        setEditMode(false);
        reload();
      });
  };

  const pdfModal = useModal();
  const openPdfModal = async () => {
    return pdfModal.open(`/companies/config/bank-matching-rules/pdf/`);
  };
  return (
    <>
      <Row className="mb-2">
        <Col className="d-flex justify-content-end">
          <ButtonGroup>
            <Button variant="secondary" onClick={openPdfModal}>
              <i className="fas fa-file-pdf" /> {t("reports:bankMatchingRulesPDF")}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <BExpandedTable
            headers={headers}
            renderExpandedRow={(row) => (
              <BankRuleEditWidget companyId={companyId} id={row.id} companyAccounts={companyAccounts} />
            )}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex justify-content-end">
          {editMode ? (
            <>
              <Button variant="secondary" className="mr-2" onClick={handleCancelClick} disabled={isSubmitting}>
                {t("common:actions.cancel")}
              </Button>
              <SubmitButton isSubmitting={isSubmitting} variant="primary" onClick={handleSaveClick}>
                {t("common:actions.save")}
              </SubmitButton>
            </>
          ) : (
            <Button variant="primary" onClick={handleEditClick}>
              {t("bankRule.buttons.editPriority")}
            </Button>
          )}
        </Col>
      </Row>
      {pdfModal.show && (
        <FileUrlModal show fileUrl={pdfModal.data} companyId={companyId} handleClose={pdfModal.close} />
      )}
    </>
  );
}

export default BankRulesExtendedTable;
