import client from "api2/client";
import qs from "qs";

export function list(filters, config) {
  return client.get(`/agencies/`, {
    params: filters,
    ...config,
  });
}

export function select(agencyId, config) {
  return client.get(`/agencies/select/`, {
    headers: {
      "x-agency": agencyId,
    },
    ...config,
  });
}

export const general = {
  details: (agencyId, config) => {
    return client.get(`/agencies/general/`, {
      headers: {
        "x-agency": agencyId,
      },
      ...config,
    });
  },
  update: (agencyId, agencyData, config) => {
    return client.put(`/agencies/general/update/`, agencyData, {
      headers: {
        "x-agency": agencyId,
      },
      ...config,
    });
  },
};
export const scheduleTemplates = {
  list: (agencyId, filters, config) => {
    return client.get(`/agencies/schedule-templates/`, {
      params: filters,
      headers: {
        "x-agency": agencyId,
      },
      ...config,
    });
  },
  save: (agencyId, templateData, config) => {
    if (templateData.id) {
      return client.put(`/agencies/schedule-templates/${templateData.id}/update/`, templateData, {
        headers: {
          "x-agency": agencyId,
        },
        ...config,
      });
    }
    return client.post(`/agencies/schedule-templates/create/`, templateData, {
      headers: {
        "x-agency": agencyId,
      },
      ...config,
    });
  },
  remove: (agencyId, templateId, config) => {
    return client.delete(`/agencies/schedule-templates/${templateId}/delete/`, {
      headers: {
        "x-agency": agencyId,
      },
      ...config,
    });
  },
  exceptions: {
    list: (agencyId, templateId, filters, config) => {
      return client.get(`/agencies/schedule-templates/${templateId}/exceptions/`, {
        params: filters,
        headers: {
          "x-agency": agencyId,
        },
        ...config,
      });
    },
    remove: (agencyId, templateId, exceptionId, config) => {
      return client.delete(`/agencies/schedule-templates/${templateId}/exceptions/${exceptionId}/delete/`, {
        headers: {
          "x-agency": agencyId,
        },
        ...config,
      });
    },
  },
};

export const permissions = {
  detail: (agencyId, consultId, config) => {
    return client.get(`/agencies/permissions/${consultId}/detail/`, {
      headers: {
        "x-agency": agencyId,
      },
      ...config,
    });
  },
  update: (agencyId, consultId, permCodes, config) => {
    return client.put(`/agencies/permissions/${consultId}/update/`, permCodes, {
      headers: {
        "x-agency": agencyId,
      },
      ...config,
    });
  },
  companyDetail: (agencyId, companyId, consultId, config) => {
    return client.get(`/agencies/permissions/${consultId}/company/detail/`, {
      params: { company_id: companyId },
      headers: {
        "x-agency": agencyId,
      },
      ...config,
    });
  },
  companyUpdate: (agencyId, companyId, consultId, permCodes, config) => {
    return client.put(
      `/agencies/permissions/${consultId}/company/update/`,
      {
        company_id: companyId,
        checked: permCodes,
      },
      {
        headers: {
          "x-agency": agencyId,
        },
        ...config,
      }
    );
  },
  summaryList: (agencyId, consultId, filters, config) => {
    return client.get(`/agencies/permissions/${consultId}/company/summary/`, {
      params: { ...filters },
      headers: {
        "x-agency": agencyId,
      },
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    });
  },
  summaryUpdate: (agencyId, consultId, summary, config) => {
    return client.put(`/agencies/permissions/${consultId}/company/summary/update/`, summary, {
      headers: {
        "x-agency": agencyId,
      },
      ...config,
    });
  },
};

export const offices = {
  list: (agencyId, filters, config) => {
    return client.get(`/agencies/offices/`, {
      params: filters,
      headers: {
        "x-agency": agencyId,
      },
      ...config,
    });
  },
  save: (agencyId, officeData, config) => {
    if (officeData.id) {
      return client.put(`/agencies/offices/${officeData.id}/update/`, officeData, {
        headers: {
          "x-agency": agencyId,
        },
        ...config,
      });
    }
    return client.post(`/agencies/offices/create/`, officeData, {
      headers: {
        "x-agency": agencyId,
      },
      ...config,
    });
  },
  remove: (agencyId, officeId, config) => {
    return client.delete(`/agencies/offices/${officeId}/delete/`, {
      headers: {
        "x-agency": agencyId,
      },
      ...config,
    });
  },
};

export const usageReports = {
  list: (filters, config) => {
    return client.get(`/agencies/usage-reports/`, {
      params: filters,
      paramsSerializer: (args) => {
        return qs.stringify(args, { indices: false });
      },
      ...config,
    });
  },
  reportPdf: (usageReportId, config) => {
    return client.get(`/agencies/usage-reports/${usageReportId}/pdf/`, {
      responseType: "blob",
      ...config,
    });
  },
};
